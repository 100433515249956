import React, {useEffect } from 'react';
import { Link } from 'react-router-dom' 
import Header from'../Component/Header';
import LazyLoad from 'react-lazyload';
const Pphelp = () => {
    useEffect(() => {
        document.title = ' Portal (Help?) - Hill Top School, Jamshedpur';  
    }, []);  
  return (
     <>
     <Header/>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                
                 <li>Portal (Help?)</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Portal (Help?)</h1>
                    </div>
                </div>
                <div class="row">
                <div class="col-xxl-6 col-xl-12">
                    <h3 class="Subhead"><b>CampusCare® 10x School ERP  </b></h3>
                        <h5> Logging In: </h5>
         
                <p> 1. Download CampusCare 10x Mobile App from   <a href="https://play.google.com/store/apps/details?id=com.entab.learninglab&hl=en-IN" target="_blank"> Play Store (Android) </a> or <a href="https://apps.apple.com/in/app/campuscare-10x/id1611283814" target="_blank">App Store (Apple)</a>.  </p>
               
                <p> 2. Open the Mobile App then enter the School Code and tap  Proceed. </p>
                <p> 3. Enter your Registered Mobile Number and verify via OTP. </p>
                <p> 4. Log in using your Username (mobile number) and the provided Password. </p>
                  <div class="mobileappimg">
                     <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile1.jpg"> 
                         <LazyLoad><img alt="pphelp" src="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile1.jpg" class="img-fluid" /></LazyLoad>
                     </a>
                  </div>
                   <h5>Navigating the App: </h5>
         
                <p class="guideheading"><strong> 1.Main Dashboard   </strong> <br/>
         
                    Once logged in, you will be directed to the Main Dashboard, displaying icons for different features. </p>
         
                <p class="guideheading"><strong> 2.Viewing Student and Parent Information:    </strong> <br/>
         
                     Click on the student's name or image to access personal and parent details.  </p>
                      <div class="mobileappimg">
                     <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile2.jpg"> 
                         <LazyLoad><img alt="pphelp" src="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile2.jpg" class="img-fluid" /></LazyLoad>
                     </a>
                  </div>
                <p class="guideheading"><strong> 3.Internal Messaging:  </strong> <br/>
         
                   Tap the message icon to communicate with school staff about assignments, homework, or any other inquiries. </p>
         
                <p class="guideheading"><strong> 4.Viewing Attendance:  </strong> <br/>
         
                    Go to the Attendance section to check your ward's attendance details. </p>
         
                <p class="guideheading"><strong> 5.Viewing Assignments, Homework, Classwork, and Activities:  </strong> <br/>
         
                    Access details for assignments, homework, classwork, and activities. </p>
         
            </div>
              <div class="col-xxl-6 col-xl-12">
                <p class="guideheading"><strong> 6.Viewing Fee Details:  </strong> <br/>
         
                     <i class="bi bi-circle-fill"></i> Fees Due: View the total fees pending for the current term/year. <br/>
                     <i class="bi bi-circle-fill"></i> Fees Paid: Check the payments made, including past installments. <br/>
                     <i class="bi bi-circle-fill"></i> Fee Breakup: See a detailed breakdown of all fees (tuition, extracurricular, etc.). <br/>
                     <i class="bi bi-circle-fill"></i> Fee Receipts: Access and download receipts for any payments made. </p>
                      <div class="mobileappimg">
                     <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile3.jpg"> 
                         <LazyLoad><img alt="pphelp" src="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile3.jpg" class="img-fluid" /></LazyLoad>
                     </a>
                  </div>
             
           
                <p class="guideheading"><strong> 7.Making Payments: </strong><br/>
         
                     <i class="bi bi-circle-fill"></i> To pay fees, click on Pay Now, select the installment, and click Proceed to Pay. <br/>
                     <i class="bi bi-circle-fill"></i> Enter payment details and Select your preferred payment method (Credit Card, Debit Card, Net Banking, etc.).  <br/>
                     <i class="bi bi-circle-fill"></i> Complete the transaction through the Payment Gateway. <br/>
                    <i class="bi bi-circle-fill"></i> Payment processing may take 4 to 24 hours. You can download the payment receipt afterward. </p>
                      <div class="mobileappimg">
                     <a data-magnify="gallery" data-src="" data-group="a" href="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile4.jpg"> 
                         <LazyLoad><img alt="pphelp" src="https://webapi.entab.info/api/image/HTSJ/public/Images/mobile4.jpg" class="img-fluid" /></LazyLoad>
                     </a>
                  </div>
                <p class="guideheading"><strong> 8.Payment Guidelines: </strong><br/>
         
                     <i class="bi bi-circle-fill"></i> Processing fees will be calculated separately. <br/>
                     <i class="bi bi-circle-fill"></i> If your payment is not reflected in your account after 24 hours, check under Paid Details or contact support. <br/>
                     <i class="bi bi-circle-fill"></i> Avoid multiple payments for the same installment if already processed. </p>
         
                <p class="guideheading"><strong> 9.Support: </strong><br/>
         
                    <i class="bi bi-circle-fill"></i> For assistance, contact <a href="mailto:parentdesk@entab.in" class="yellow-link"> parentdesk@entab.in</a> or use the Need Help section in the app. <br/>
                     <i class="bi bi-circle-fill"></i> ChatBot and email support are available for queries regarding the app or payments. </p>
                </div>
            </div>
            </div>
        </div>
     </>
  )
}

export default Pphelp

