import React, { useEffect, useState } from "react";
import { getNewsInner } from "../Service/Api";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import LazyLoad from "react-lazyload";

const News = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNewsInner();
        setNewsData(data);
        console.log("n", data);
      } catch (error) {
        console.error("Failed to fetch news:", error);
        setNewsData([]); // Set to empty to ensure the empty state is handled
      }
    };
    fetchData();
  }, []);
  const emptyArray = [
    {
      title: "Stay Tuned For More Updates",
      description: "Stay Tuned For More Updates",
    },
    {
      title: "Stay Tuned For More Updates",
      description: "Stay Tuned For More Updates",
    },
    {
      title: "Stay Tuned For More Updates",
      description: "Stay Tuned For More Updates",
    },
  ];

  const settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 2 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 767, settings: { slidesToShow: 2 } },
      { breakpoint: 600, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <>
      <div className="eventbg">
        <Slider {...settings}>
          {newsData.length > 0
            ? newsData.map((item, index) => (
              <div className="item" key={index}>
                <div className="homeeventbox">
                  <div className="homeeventboximg newseventbg">
                    <div className="overlay">
                      <button className="dark-button">Read More</button>
                    </div>
                    <LazyLoad>{item.image.length > 0 ? (<img src={`https://webapi.entab.info/api/image/${item.image}`} className="img-fluid" alt="HTS" />) : (<img src="https://webapi.entab.info/api/image/HTSJ/public/Images/news.jpg" className="img-fluid" alt="Jai Rani public School" />)} </LazyLoad><div className="attch">
                      <Link
                        to={`https://webapi.entab.info/api/image/${item?.attachments}`}
                        target="_blank">
                        <i class="bi bi-paperclip"></i>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="homeeventboxdesc">
                    <div className="homeeventboxtitle">
                      <h3>{item.title}</h3>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))
            : emptyArray.map((data, index) => (
              <div className="item" key={index}>
                <div className="homeeventbox">
                  <div className="homeeventboximg">
                    <div className="overlay">
                      <button className="dark-button">Read More</button>
                    </div>
                    <LazyLoad><img
                      src="https://webapi.entab.info/api/image/HTSJ/public/Images/news.jpg"
                      className="img-fluid"
                      alt="HTSJ"
                    /></LazyLoad>
                    <div className="attch">
                      <Link to="/" target="_blank">
                        <i class="bi bi-paperclip"></i>{" "}
                      </Link>
                    </div>
                  </div>
                  <div className="homeeventboxdesc">
                    <div className="homeeventboxtitle">
                      <h3>{data.title}</h3>
                      <p>{data.desc}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
        <button className="button">
          <Link to="/News">Read More</Link>
        </button>
      </div>
    </>
  );
};

export default News;
