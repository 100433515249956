import React  from 'react';  
import Header from'../Component/Header'
import { Link } from 'react-router-dom';
const VisionMission = () => { 
  return (
     <>
     <Header/>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li> About Us</li>
                 <li>Vision and Mission</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Vision and Mission</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <h3>Vision </h3>
                     <p>We aim to facilitate value based holistic education that combines the spirit of enquiry with positive social attitudes to nurture sensitive human beings.</p>
                     <h3>Mission </h3>
                     <p>To bring about the intellectual emotional, social and physical development of each pupil to the full potential.</p>
                     <h3>Values</h3>
                       <ul className="list">
                       <li>  Child Focous </li>
                       <li> Discipline </li>
                       <li> Accountability </li>
                       <li> Integrity </li>
                       <li> Transparency </li>
                       </ul>
                       <h3>Objective</h3>
                        <div className="mainbox">
                            <p> Hill Top School is a co-educational English Medium school primarily for the children of the employees of Tata Motors.  </p>
                        <p> The school is affiliated to the Council for the Indian School Certificate Examinations. </p>
                        <p> Pupils are prepared for the Indian Certificate of Secondary Education Examination (ICSE at Class 10) and Indian School Certificate Examination ( ISC at Class 12) </p>
                        <p> The medium of instruction is English. Hindi is compulsory till class X. Sanskrit is offered as third language. </p>
                        </div>
                     </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default VisionMission

