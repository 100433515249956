import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from'../Component/Header';
import { getPrincipalMessage } from "../Service/Api";
import LazyLoad from "react-lazyload";
const PrincipalMessage = () => {
  useEffect(() => {
    document.title =
      "Our Principal - Mar Gregorios Memorial Hr. Sec. School, Bokaro";
  }, []);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(data);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const principalData = await getPrincipalMessage();
        setData(principalData);
      } catch (error) {
        console.log("Data Error", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const emptyData = [{ id: 1 }];
  return (
    <>
     <Header/>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>

          <li> Principal</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1> Principal </h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {data.length > 0
                ? data.map((item) => (
                    <div className="clearfix">
                      <LazyLoad
                        offset={10}
                        placeholder={
                          <img
                            src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                            alt="Loading..."
                            className="img-fluid"
                          />
                        }>
                        <img
                          src={`https://webapi.entab.info/api/image/${item.attachments}`}
                          alt=""
                          className="col-md-4 float-md-end  left-img img-fluid"
                        />
                      </LazyLoad>

                      <div dangerouslySetInnerHTML={{ __html: item.message }} />
                      <div className="mesdeskimg">
                        <h3> {item.name}</h3>
                        <h5> {item.category}</h5>
                      </div>
                    </div>
                  ))
                : emptyData.map((item) => (
                    <div className="clearfix">
                     <LazyLoad> <img
                        src="https://webapi.entab.info/api/image/HTSJ/public/Images/principal.jpg"
                        className="col-md-4 float-md-end  left-img img-fluid"
                      /></LazyLoad>

                      <p>
                        As we embark on a new academic year, we are filled with
                        excitement and anticipation for the wonderful learning
                        journey ahead. At Hill Top School, we believe in
                        fostering an environment that nurtures academic
                        excellence, character development, and a sense of
                        community. Our dedicated team of educators is committed
                        to providing the highest quality of education and
                        equipping our students with the knowledge, skills, and
                        values necessary to thrive in an ever-changing world.
                      </p>
                      <div className="mesdeskimg">
                        {" "}
                        <h3> MRS. Uma Tiwary</h3> <h5> Principal </h5>
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrincipalMessage;
