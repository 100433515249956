import React  from 'react'; 
import { Link } from "react-router-dom";
import Header from'../Component/Header';
const CodeConduct = () => { 
  return (
     <>
     <Header/>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Policies</li>
                 <li> Code Of Conduct</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1> Code Of Conduct</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <h3>Guidelines For Ethical Practice</h3>
                     <p>Some early treatises—such as those of Cicero on the pleasantness of old age or on the art of “divination,” Seneca on anger or clemency, and Plutarch on the passing of oracles—presage to a certain degree the form and tone of the essay, but not until the late 16th century was the flexible and deliberately nonchalant and versatile form of the essay perfected by the French writer Michel de Montaigne.</p>
                      </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default CodeConduct

