import React from 'react'
import LazyLoad from 'react-lazyload'
import { Link } from 'react-router-dom'
const Campuscare = () => {
  return (
     <>
      <div className="campuslogo">
         <Link to="https://entab.online/HTSJSR" target="_blank">
            <LazyLoad><img src="https://webapi.entab.info/api/image/HTSJ/public/Images/Campulogo.png" className="img-fluid float-end campus" alt="HTS"/></LazyLoad>
            </Link>
      </div>
      </>
        
  )
}

export default Campuscare
