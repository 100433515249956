import { useEffect, useState } from "react";
import Slider from "react-slick";
import { getTopper } from "../Service/Api";
import LazyLoad from "react-lazyload";
const TopperXII = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topperData = await getTopper();
        setData(topperData);
      } catch (error) {
        console.error("Error fetching topper data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    console.log("topper_data", data);
    console.log("topper_data", data.length + "test");
  }, [data]);

  const emptyArray = [
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments:
        "https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments:
        "https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments:
        "https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments:
        "https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments:
        "https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png",
    },
    {
      name: "Student Name",
      stream: "Subject",
      division: "100%",
      attachments:
        "https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png",
    },
  ];
  var settings = {
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {data.filter((item) => item.class === "XII").length > 0
          ? data
              .filter((item) => item.class === "XII")
              .map((item, index) => (
                <div className="item" key={index}>
                  <div className="topperdiv">
                    <div className="toppertaj">
                      <LazyLoad
                        offset={10}
                        placeholder={
                          <img
                            src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                            alt="Loading..."
                            className="img-fluid"
                          />
                        }>
                        <img
                          src={
                            item.attachments && item.attachments[0]
                              ? `https://webapi.entab.info/api/image/${item.attachments[0]}`
                              : "https://webapi.entab.info/api/image/HTSJ/public/Images/trophy.png"
                          }
                          className="img-fluid"
                        />
                      </LazyLoad>
                    </div>
                    <div className="topperdesc">
                      <p className="name">{item.name} </p>
                      <p className="per">{item.division}</p>
                      <p>{item.stream}</p>
                    </div>
                  </div>
                </div>
              ))
          : emptyArray.map((data, index) => (
              <div className="item" key={index}>
                <div className="topperdiv">
                  <div className="toppertaj">
                    <LazyLoad><img src={data.attachments} className="img-fluid" /></LazyLoad>
                  </div>
                  <div className="topperdesc">
                    <p className="name">{data.name} </p>
                    <p className="per">{data.division}</p>
                    <p>{data.stream}</p>
                  </div>
                </div>
              </div>
            ))}
      </Slider>
    </>
  );
};

export default TopperXII;
