import React  from 'react'; 
import { Link } from "react-router-dom";
import Header from'../Component/Header';
const CodeStudents = () => { 
  return (
     <>
     <Header/>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li> 
                <li>  Students</li>
                 <li>Code Students</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Code Students</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <h3> Code Of Conduct </h3>
                     <p>We believe that school is a place for teaching & learning, where the pupil is the primary focus, and where students have the right to feel safe, secure and supported. To provide this supportive learning environment, students and others must foster respect & discipline among all members of the school community. Our school CODE OF CONDUCT encourages students to recognize and respect diversity and individual differences. In the process, we want students to develop self- discipline, which should be considered as a core of personal activity. We expect our students to maintain and encourage high standards of personal conduct. These include discipline, integrity, respect, accountability, sensitivity and values.</p>
                     
                     
                     <h3> Responsibilities Towards School & The Class </h3>
                     <ul className="list">
                       <li>To protect and promote the sanctity of the school. </li>
                     <li>To maintain peace, order and harmony in school. </li>
                     <li>To reach school on time, & attend and maintain the dignity and sanctity of the school assembly. </li>
                     <li>To develop neatness and orderliness in their work and appearance.. </li>
                     <li>To speak softly and only in English while at school. </li>
                     <li>To treat the helpers of the school with respect. </li>
                     <li>To treat the helpers of the school with respect. </li>
                     <li>To treat the helpers of the school with respect. </li>
                     <li>To regularly submit school work: written work such as class work, home work, and assignments must be completed punctually and regularly </li>
                     <li>To follow the general instructions given in the school by teachers. </li>
                     <li>To take part in the school elections and discharge the duties as expected in the governance of the school and follow the rules and regulations. </li>
                     <li>To safeguard and preserve school property. Any damage done to the property of the school is considered a serious breach of discipline and will invite disciplinary action and heavy penalties. </li>
                     <li>To respect elders, teachers, support staff and friends & to be socially responsible in the society. </li>
                     </ul>

                     <h3>Dress Code</h3>
                     <p>Students must be dressed in the school uniform whenever they are on the school premises. Students need to be in complete school uniform when:</p>
                     </div>
                </div>
            </div>
        </div>
     </>
  )
}

export default CodeStudents

