import React from "react";
import { Link } from "react-router-dom";
import Header from'../Component/Header';
import LazyLoad from "react-lazyload";
const Facility = () => {
  return (
    <>
     <Header/>
      <div className="innerslide">
        <ul className="breadcrumb">
          <li>
            <Link to="/"> Home</Link>{" "}
          </li>
          <li> Students</li>
          <li>Facilities</li>
        </ul>
      </div>
      <div className="innersec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1> Facilities</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <a
                    data-magnify="gallery"
                    data-src=""
                    data-group="a"
                    href="https://webapi.entab.info/api/image/HTSJ/public/Images/facility1.jpg">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        className="img-fluid mx-auto d-block smartclass"
                        src="https://webapi.entab.info/api/image/HTSJ/public/Images/facility1.jpg"
                        alt="mgmb"
                      />
                    </LazyLoad>
                  </a>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <a
                    data-magnify="gallery"
                    data-src=""
                    data-group="a"
                    href="https://webapi.entab.info/api/image/HTSJ/public/Images/facility2.jpg">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        className="img-fluid mx-auto d-block smartclass"
                        src="https://webapi.entab.info/api/image/HTSJ/public/Images/facility2.jpg"
                        alt="mgmb"
                      />
                    </LazyLoad>
                  </a>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <a
                    data-magnify="gallery"
                    data-src=""
                    data-group="a"
                    href="https://webapi.entab.info/api/image/HTSJ/public/Images/facility3.jpg">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        className="img-fluid mx-auto d-block smartclass"
                        src="https://webapi.entab.info/api/image/HTSJ/public/Images/facility3.jpg"
                        alt="mgmb"
                      />
                    </LazyLoad>
                  </a>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <a
                    data-magnify="gallery"
                    data-src=""
                    data-group="a"
                    href="https://webapi.entab.info/api/image/HTSJ/public/Images/facility4.jpg">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        className="img-fluid mx-auto d-block smartclass"
                        src="https://webapi.entab.info/api/image/HTSJ/public/Images/facility4.jpg"
                        alt="mgmb"
                      />
                    </LazyLoad>
                  </a>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-6">
                  <a
                    data-magnify="gallery"
                    data-src=""
                    data-group="a"
                    href="https://webapi.entab.info/api/image/HTSJ/public/Images/facility5.jpg">
                    <LazyLoad
                      offset={10}
                      placeholder={
                        <img
                          src="https://webapi.entab.info/api/image/HTSJ/public/Images/sld0.jpg"
                          alt="Loading..."
                          className="img-fluid"
                        />
                      }>
                      <img
                        className="img-fluid mx-auto d-block smartclass"
                        src="https://webapi.entab.info/api/image/HTSJ/public/Images/facility5.jpg"
                        alt="mgmb"
                      />
                    </LazyLoad>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Facility;
