import React, { useState } from "react"; 
import { Link } from "react-router-dom";
import Header from'../Component/Header'; 
import { form1 } from "../Service/Api";
import { getHeader } from "../config";

const ContactUs = () => { 
    const [formData, setFormData] = useState({
        name: "", 
        phone: "",
        email: "",
        subject: "",
        message: "",
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrors({ ...errors, [e.target.name]: "" }); // Clear the error for the field being changed
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = "Name is required.";
        if (!formData.email) {
            newErrors.email = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = "Email is not valid.";
        }
        if (!formData.phone) newErrors.phone = "Phone Number is required.";
        if (!formData.subject) newErrors.subject = "Subject is required.";
        if (!formData.message) newErrors.message = "Message is required.";
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        const type = 'abc'; 
        console.log('Submitting form with type:', type); // Debugging line
        try {
            const { header } = getHeader();
            const response = await form1(formData, header, type); 
            console.log('Form submitted successfully', response);
            document.querySelector(".primary-alert").innerHTML = "<p>Your Form Submitted Successfully.</p>";
            setFormData({
                name: "", 
                phone: "",
                email: "",
                subject: "",
                message: "",
            });
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <>
        <Header/>
            <div className="innerslide">
                <ul className="breadcrumb">
                    <li><Link to="/"> Home</Link> </li>  
                    <li>ContactUs</li>
                </ul>
            </div> 
            <div className="innersec">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <h1>ContactUs</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="formbox">
                            <div class="formboxleft">
	<h5>Get In Touch</h5>
	<div class="addressdesc">
		<div class="addressimg"> <i class="bi bi-geo-alt-fill"></i></div>
		<Link to="https://maps.app.goo.gl/Zmh4Nw981YzdZKaR6" target="_blank">Hill Top School H. S. Road, Telco Colony, Jamshedpur -831 004, Jharkhand</Link>
	</div>
	<div class="addressdesc">
		<div class="addressimg"><i class="bi bi-envelope-fill"></i></div>
		<Link to="mailto:hilltopschooljsr@gmail.com">hilltopschooljsr@gmail.com </Link> <br/>
		<Link to="mailto:contact@hilltopschooljamshedpur.org">  contact@hilltopschooljamshedpur.org</Link>
	</div>
		<div class="addressdesc">
			<div class="addressimg"><i class="bi bi-telephone-fill"></i></div>
			<Link to="tel:0657-2286677">0657 - 2286677  , </Link>
			<Link to="tel:0657 - 2284061"> 0657 - 2284061</Link>
		</div>
</div>
                                <div className="formboxright"> 
                                    <form onSubmit={handleSubmit}>
                                        <div className="formdiv">
                                            <div className="form-group">
                                                <label>Name<span>*</span></label>
                                                <input type="text" name="name" className="form-control" value={formData.name} onChange={handleChange}/>
                                                {errors.name && <div className="error">{errors.name}</div>}
                                            </div>
                                            <div className="form-group">
                                                <label>Email<span>*</span></label>
                                                <input type="text" name="email" className="form-control" value={formData.email} onChange={handleChange}/>
                                                {errors.email && <div className="error">{errors.email}</div>}
                                            </div>
                                            <div className="form-group">
                                                <label>Phone No.<span>*</span></label>
                                                <input type="text" name="phone" className="form-control" value={formData.phone} onChange={handleChange}/>
                                                {errors.phone && <div className="error">{errors.phone}</div>}
                                            </div>
                                            <div className="form-group">
                                                <label>Subject<span>*</span></label>
                                                <input type="text" name="subject" className="form-control" value={formData.subject} onChange={handleChange}/>
                                                {errors.subject && <div className="error">{errors.subject}</div>}
                                            </div>
                                            <div className="form-group textarea">
                                                <label>Your Message<span>*</span></label>
                                                <textarea name="message" className="form-control" value={formData.message} onChange={handleChange}></textarea>
                                                {errors.message && <div className="error">{errors.message}</div>}
                                            </div>
                                        </div>
                                        <input type="submit" value="Submit"/>
                                        <div className="row"> 
                                            <div className="col-lg-12 col-md-12">
                                                <div className='primary-alert'></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3678.6543053520313!2d86.247872!3d22.778207!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f5e2af77d4f425%3A0xa3ae45e5108c79f6!2sHill%20Top%20School%2C%20Jamshedpur!5e0!3m2!1sen!2sin!4v1725538129672!5m2!1sen!2sin" width="100%" height="300" style={{border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    );
}

export default ContactUs;
