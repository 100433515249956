import React  from 'react'; 
import { Link } from "react-router-dom";
import Header from'../Component/Header';
const Calendar = () => { 
  return (
     <>
     <Header/>
       <div className="innerslide">
            <ul className="breadcrumb">
                <li><Link to="/"> Home</Link> </li>  
                <li>Activity</li>
                 <li>Calendar</li>
            </ul>
        </div> 
        <div className="innersec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <h1>Calendar</h1>
                    </div>
                </div>
                <div className="row">
                     <div className="col-12">
                     <iframe src="https://webapi.entab.info/api/image/HTSJ/public/pdf/Calendar.pdf#toolbar=0&navpanes=0&scrollbar=0" width="100%" height="1000px"></iframe>
                    </div>
                </div> 
            </div>
        </div>
     </>
  )
}

export default Calendar

