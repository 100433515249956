import React, { useState } from "react";
import LazyLoad from "react-lazyload";
 

const ClassTabs = () => {
    // State to keep track of the active tab
    const [activeTab, setActiveTab] = useState(0);

    // Array of tab data including images and content
    const tabData = [
        {
            title: <>Pre Primary <br />School</>,
            image: "https://webapi.entab.info/api/image/HTSJ/public/Images/pre-primary.jpg", // Use the imported image
            content: "The Pre-primary segment of Hill Top is centred at the Raman Block. It is where the future is incubated. The curriculum has been mindfully drawn out to facilitate and scaffold ACTIVE LEARNING that not only involves the inculcation of desirable social attitudes, healthy group behaviour but also focuses on stoking their intellectual curiosity and creativity. Apart from helping the 3-4 year olds acquire basic language and mathematical skills and teaching them self regulation, the school encourages them to explore, experiment and question. These goals are achieved by conducting special morning assemblies, Montessori activities and through effectively designed worksheets , use of innovative teaching aids, celebration of important festivals and days and by encouraging participation in a host of school events like JAMBOREE and ENGLOQUENCE to name a few. The early childhood learning opportunities thus provided also fulfil another important goal which is to reduce the educational gap between children from the vulnerable and relatively privileged and affluent families",
            readMore: '/'
        },
        {
            title: <>Primary <br />School</>,
            image: "https://webapi.entab.info/api/image/HTSJ/public/Images/primary.jpg", // Use the imported image
            content: "The Sarabhai Block is the locus of numerous engaging activities that bolster the harmonious growth and development of the pupils of the primary segment. The supportive and nurturing environment at Hill Top enables the imaginative faculty of each child to blossom thus making him or her more confident and turning him/her into a self-directed learner. Besides ensuring the moral, cultural, mental, physical, emotional and social development of each child, Hill Top empowers its pupils to become sensitive and concerned individuals that are aware of their responsibilities towards the school and country. They are taught to embrace a healthy lifestyle and navigate the multiple challenges posed by technological advancements.",
            readMore: '/'
        },
        {
            title: <>Middle<br /> School</>,
            image: "https://webapi.entab.info/api/image/HTSJ/public/Images/middle-school.jpg", // Use the imported image
            content: "The success of the Middle School Segment stems from the robust strategies, processes and unique practices at Hill Top, a gamut of events, activities, Talent Encouragement Exams, Olympiads and Value Education Programmes help the students redirect their development into a more productive trajectory despite the post pandemic challenges. Workshops addressing pertinent issues related to the age bracket of 10 - 14 are conducted that further succour the preteens and teens.",
            readMore: '/'
        },
        {
            title: <>Secondary <br />School</>,
            image: "https://webapi.entab.info/api/image/HTSJ/public/Images/secondary.jpg", // Use the imported image
            content: "Hailed as the nerve centre of Hill Top, the Kalam Block is where the Secondary and Senior Secondary Segments operate from. Each teacher is resolutely committed to delivering engaging scholastic instruction to students. The segment, being the launchpad of our students, into the world, focuses on helping them to realise the significance of readily shouldering responsibilities, understanding the value of cooperation, collaboration and industry. Through active interaction and delegation of specific roles and responsibilities to the School Cabinet and the Safety Squad, the students of the two segments are empowered and engaged. They also gain perspective of their own strengths and weaknesses. Regular counselling of students, helps the school reinforce values such as respecting all genders, rejecting stereotypes, violence and prejudices and being environmentally conscious. The segment also focuses on developing the core competencies of students such as communication skills, developing both: a scientific temperament and an entrepreneurial spirit, embracing technology, leadership skills, preparing themselves for the board exams and for navigating the many transitions in the life ahead.",
            readMore: '/'
        },
        {
            title: <>Senior <br />School  </>,
            image: "https://webapi.entab.info/api/image/HTSJ/public/Images/senior.jpg", // Use the imported image
            content: "Hailed as the nerve centre of Hill Top, the Kalam Block is where the Secondary and Senior Secondary Segments operate from. Each teacher is resolutely committed to delivering engaging scholastic instruction to students. The segment, being the launchpad of our students, into the world, focuses on helping them to realise the significance of readily shouldering responsibilities, understanding the value of cooperation, collaboration and industry. Through active interaction and delegation of specific roles and responsibilities to the School Cabinet and the Safety Squad, the students of the two segments are empowered and engaged. They also gain perspective of their own strengths and weaknesses. Regular counselling of students, helps the school reinforce values such as respecting all genders, rejecting stereotypes, violence and prejudices and being environmentally conscious. The segment also focuses on developing the core competencies of students such as communication skills, developing both: a scientific temperament and an entrepreneurial spirit, embracing technology, leadership skills, preparing themselves for the board exams and for navigating the many transitions in the life ahead.",
            readMore: '/'
        },
        {
            title: <>Atal Tinkering <br />Lab</>,
            image: "https://webapi.entab.info/api/image/HTSJ/public/Images/atal-tinkering-lab.jpg", // Use the imported image
            content: "As a progressive school with a dynamic culture Hill Top is steadfastly committed to fostering neoteric teaching methodologies that provide momentum to inventiveness, originality and enterprise! It is believed that the one who tinkers emerges as a thinker, therefore, in the year 2019 the Atal Tinkering Lab was established in line with the Central Government of India`s mission to help students of Grades 6 to 12 to develop a scientific temperament. The ATL is our STEM-focused nucleus of innovation and the academic programme is hands on and purely experiential...",
            readMore: '/'
        }
    ];

    return (
        <div className="class-tabs">
            <div className="tabs">
                {tabData.map((tab, index) => (
                    <div
                        key={index}
                        className={`tab ${activeTab === index ? "active" : ""}`}
                        onClick={() => setActiveTab(index)}
                    >
                    <div className="tab-title">{tab.title}</div>
                        <div className="tabs-img">
                            <LazyLoad><img src={tab.image} alt={tab.title} className="tab-image" /></LazyLoad>
                        </div>
                    </div>
                ))}
            </div>
            <div className="tabs-content">
                <div className="content">
                  
                    <p>{tabData[activeTab].content}</p>
                    <button className="button" href={tabData.readMore}>Read More</button>
                </div>
            </div>
        </div>
    );
};

export default ClassTabs;
