
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Events from'./Pages/Events'
import Gallery from'./Pages/Gallery'
import SubGallery from'./Pages/SubGallery'
import Toppers from'./Pages/Toppers' 
import Tc from'./Pages/Tc'
import Pphelp from'./Pages/Pphelp'
import ToppersXII from'./Pages/ToppersXII' 
import Footer from'./Component/Footer'
import ErpSoftware from'./Pages/ErpSoftware'
import News from'./Pages/News'
import VideoGallery from'./Pages/VideoGallery'
import PrincipalMessage from'./Pages/PrincipalMessage'
import VisionMission from'./Pages/VisionMission'
import CodeStudents from'./Pages/CodeStudents'
import Attendance from'./Pages/Attendance'
import CodeConduct from'./Pages/CodeConduct'
import Exam from'./Pages/Exam'
import Facility from'./Pages/Facility'
import Fees from'./Pages/Fees' 
import Withdrawals from'./Pages/Withdrawals'
import StudentCouncil from'./Pages/StudentCouncil'
import HillTopFamily from'./Pages/HillTopFamily'
import StudentBlog from'./Pages/StudentBlog'
import TeacherBlog from'./Pages/TeacherBlog'
import Admission from'./Pages/Admission'
import ContactUs from'./Pages/ContactUs'
import Careers from'./Pages/Careers'
import Calendar from'./Pages/Calendar'
import Club from'./Pages/Club'
import Reflections from'./Pages/Reflections'
import About from'./Pages/About'
import Suggestion from'./Pages/Suggestion' 
import Home from'./Pages/Home'  
import Magazine from'./Pages/Magazine'
function App() { 
  return (
      <>
      <BrowserRouter>
      
      <Routes>
       <Route path="/" element={<Home/>} />
       <Route path="/Events" element={<Events/>} />
       <Route path="/Gallery" element={<Gallery/>} />
       <Route path="/SubGallery" element={<SubGallery/>} /> 
       <Route path="/Toppers" element={<Toppers/>} />
       <Route path="/Tc" element={<Tc/>} />
       <Route path="/Pphelp" element={<Pphelp/>} />
       <Route path="/ToppersXII" element={<ToppersXII/>} />
       <Route path="/ErpSoftware" element={<ErpSoftware/>} /> 
       <Route path="/PrincipalMessage" element={<PrincipalMessage/>} /> 
       <Route path="/News" element={<News/>} />
       <Route path="/VideoGallery" element={<VideoGallery/>} />
        <Route path="/VisionMission" element={<VisionMission/>} />
         <Route path="/CodeStudents" element={<CodeStudents/>} />
          <Route path="/Attendance" element={<Attendance/>} />
          <Route path="/CodeConduct" element={<CodeConduct/>} />
          <Route path="/Exam" element={<Exam/>} />
          <Route path="/Facility" element={<Facility/>} />
         <Route path="/Fees" element={<Fees/>} />
          <Route path="/Withdrawals" element={<Withdrawals/>} />
          <Route path="/StudentCouncil" element={<StudentCouncil/>} />
          <Route path="/HillTopFamily" element={<HillTopFamily/>} />
          <Route path="/StudentBlog" element={<StudentBlog/>} />
          <Route path="/TeacherBlog" element={<TeacherBlog/>} />
          <Route path="/Admission" element={<Admission/>} />
          <Route path="/ContactUs" element={<ContactUs/>} />
          <Route path="/Careers" element={<Careers/>} />
          <Route path="/Calendar" element={<Calendar/>} />
          <Route path="/Club" element={<Club/>} />
          <Route path="/Reflections" element={<Reflections/>} />
          <Route path="/About" element={<About/>} />
           <Route path="/Suggestion" element={<Suggestion/>} />
           <Route path="/Magazine" element={<Magazine/>} />
      </Routes>
      <Footer/>
     </BrowserRouter>
      </>
  )
}

export default App;
