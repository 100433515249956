import Header from'../Component/Header'; 
import { Link } from 'react-router-dom'
import React, {useEffect } from 'react';
const Magazine = () => {
    useEffect(() => {
        document.title = 'Magazine - Hill Top School, Jamshedpur';  
    }, []);  
 
  return (
      <>
      <Header/>
      <div className="innerslide">
      <ul className="breadcrumb">
          <li><Link to="/"> Home</Link> </li> 
          <li>  Gallery</li>
           <li>Magazine </li>
      </ul>
  </div> 
  <div className="innersec">
      <div className="container">
          <div className="row">
              <div className="col-lg-12">
                  <h1>Magazine </h1>
              </div>
          </div>
              <div className="row">
             <div className="col-lg-12"> 
                <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 videosearch">
                    <div className="video-gallery">
                        <Link to="https://webapi.entab.info/api/image/HTSJ/public/pdf/Sanctuary.pdf" target="_blank"> 
                            <h3>Sanctuary</h3> 
                        </Link>
                    </div>
                </div> 
               </div>
            </div> 
         </div> 
     </div>  
       
      </>
  )
}

export default Magazine
